import * as React from "react";
import LogoHeader from "./logo_header";
import "../index.css";

const styleInline = {
  display: "inline-block",
};

export default ({ link, prefix = "Welcome to", suffix = "" }) => {
  var suffix_styled = suffix ? (
    <h1 className='welcome-header-top'>&nbsp;{suffix}</h1>
  ) : (
    <></>
  );

  return (
    <>
      <h1 className='welcome-header-bottom'>{prefix}</h1>
      {link ? (
        <div>
          <a style={styleInline} href="/">
            <LogoHeader link={true} />
          </a>{" "}
          {suffix_styled}
        </div>
      ) : (
        <div>
          <LogoHeader style={styleInline} link={false} />
          {suffix_styled}
        </div>
      )}
    </>
  );
};
