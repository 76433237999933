import * as React from "react";
import logo from "../assets/MentorAmp_Logo.png";

export default (props) => {
  if (props.link) {
    return (
      <a href="/">
        <img
          src={logo}
          alt="MentorAmp logo"
          style={{ width: props.pixelWidth || 250 }}
        />
      </a>
    );
  } else {
    return (
      <img
        src={logo}
        alt="MentorAmp logo"
        style={{ width: props.pixelWidth || 250 }}
      />
    );
  }
};
